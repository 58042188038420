import styles from './Search.module.scss'
import { connect } from 'react-redux'
import SearchInputField from "@/gdeluLib/SearchInputField/SearchInputField"
import { changeLeftClubsBlock } from "@/store/clubs/leftBlock/actions"
import { useEffect, useState } from "react"
import { searchClubs } from "@/store/clubs/filters/search/actions"
import Arrow from '../../../../assets/icons/arrow.svg'
import { getHistorySearch } from "@/store/clubs/filters/search/actions"
import { resetModalModel } from "@/store/modal/actions"

const Search = ({
                  search,
                  searchClubs,
                  changeLeftClubsBlock,
                  leftBlock,
                  getHistorySearch,
                  selected,
                  isGlobalSearch,
                  resetModalModel
                }) => {
  const [ _search, setSearch ] = useState(search.slice())
  const { isOpen, blockNames, name } = leftBlock

  const { price, geo } = selected
  const hasSelected = Object.keys({ ...selected.subways, ...selected.services, ...selected.options, ...selected.nets }).length > 0 || price.min || geo.lat


  useEffect(() => {
    getHistorySearch()
  }, [ search, hasSelected ])

  useEffect(() => {
    setSearch(search.slice())
  }, [ search ])

  const backHandler = () => {
    changeLeftClubsBlock({ name: blockNames.clubList })
    if (isGlobalSearch) resetModalModel()
  }

  return (
    <div className={ styles.root + (isOpen && name === blockNames.searchList ? ' ' + styles.rootOpen : '') }>
      <Arrow className={ styles.arrow }
             onClick={ backHandler }/>
      <div className={ styles.container }
           onClick={ changeLeftClubsBlock.bind(null, { isOpen: true, name: blockNames.searchList }) }>
        <SearchInputField disableDropDown={ true }
                          dropDownList={ [] }
                          focusInput={ isGlobalSearch }
                          showPlaceholderIcon={ true }
                          onChange={ e => searchClubs(e, false, true) }
                          value={ _search }
                          placeholder={ 'Что будем искать?' }/>
      </div>
    </div>
  )
}

// refact
const mapStateToProps = state => ({
  search: state.clubs.filters.search.value,
  leftBlock: state.clubs.leftBlock,
  selected: state.clubs.filters.global.selected
})

const mapDispatchToProps = dispatch => ({
  searchClubs: (value, dontSearch, withFilters) => dispatch(searchClubs(value, dontSearch, withFilters)),
  changeLeftClubsBlock: (data) => dispatch(changeLeftClubsBlock(data)),
  getHistorySearch: () => dispatch(getHistorySearch()),
  resetModalModel: () => dispatch(resetModalModel()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
