import styles from './Option.module.scss'
import RemoveIcon from '../../../../../assets/icons/remove.svg'

const Option = ({
                  icon,
                  item,
                  onClickHandler = () => {},
                  isActive,
                  clickRemove,
                  titleKey = 'label',
                  setIconColorKey
                }) => {
  return (
    <>
      <div className={ styles.item + ' body2' + (isActive ? ' ' + styles.isActive : '') }
           onClick={ clickRemove ? clickRemove.bind(null, { ...item, icon }) : onClickHandler.bind(null, {
             ...item,
             icon
           }) }>
        { icon && typeof icon !== 'string' ?
          <div className={ styles.icon + (setIconColorKey && item[ setIconColorKey ] ? ' ' + styles.iconColor : '') }
               style={ setIconColorKey ? { fill: '#' + item[ setIconColorKey ] } : {} }>{ icon }</div> : null }
        { item[ titleKey ] }
        { clickRemove ?
          <RemoveIcon className={ styles.remove }/> : null }
      </div>
    </>
  )
}

export default Option
