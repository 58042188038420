import React, { useState, useEffect, Fragment, useRef } from 'react'
import { withRouter } from "next/router"
// import InputField from "../InputField/InputField"
import styles from './SearchInputField.module.scss'
import Search from "./search.svg"

const SearchInputField = ({
                            value = '',
                            placeholder,
                            sizeInput = 's',
                            onChange = () => {},
                            dropDownList,
                            itemKey = 'label',
                            codeKey = 'code',
                            changeSearch,
                            filtered,
                            changeSearchValue,
                            prefix,
                            router,
                            inputClassName,
                            secondTitle,
                            emptyMessage,
                            disableDropDown,
                            showPlaceholderIcon,
                            focusInput
                          }) => {

  const [ showDropDown, setShowDropDown ] = useState(false)
  const [ searchValue, setSearchValue ] = useState(value.slice())
  const inputRef = useRef()
  const [ dropDownListFiltered, setDropDownListFiltered ] = useState(JSON.parse(JSON.stringify(dropDownList)))

  useEffect(() => {
    //setSearchValue('')
  }, [ router.asPath ])

  useEffect(() => {
    setDropDownListFiltered(JSON.parse(JSON.stringify(dropDownList)))
  }, [ dropDownList ])

  useEffect(() => {
    setSearchValue(value.slice())
    if (inputRef?.current && focusInput) inputRef.current.focus()
  }, [ value ])

  useEffect(() => {
    return () => {
      setSearchValue('')
      setShowDropDown(false)
      setDropDownListFiltered(JSON.parse(JSON.stringify(dropDownList)))
    }
  }, [])

  let target

  const dropDownSizeStyle = {
    s: {
      paddingTop: '80px',
      maxHeight: '279px'
    },
    m: {
      paddingTop: '97px',
      maxHeight: '311px'
    },
    dropDownContainer: {
      s: {
        maxHeight: '160px',
        paddingLeft: '20px'
      },
      m: {
        maxHeight: '177px',
        paddingLeft: '40px'
      }
    }
  }

  const fieldClickHandler = (event) => {
    target = event.currentTarget
    if (!(!!showDropDown)) {
      setShowDropDown(true)
      document.getElementById('App').addEventListener('click', hideDropDown)
    }
  }

  const hideDropDown = (event) => {
    console.log(event.target, target, target.contains(event.target))
    if (event.target !== target && !(!!target.contains(event.target))) {
      setShowDropDown(false)
      try {
        document.getElementById('App').removeEventListener('click', hideDropDown)

      } catch (err) {
      }
    }
  }

  useEffect(() => {
    return () => {
      try {
        document.getElementById('App').removeEventListener('click', hideDropDown)
      } catch (err) {
      }
    }
  }, [])

  const selectItem = (item) => {
    setShowDropDown(false)

    try {
      document.getElementById('App').removeEventListener('click', hideDropDown)
      if (filtered) {
        let value = item[ itemKey ]
        filteredItem(value)
        setSearchValue(prefix ? item[ prefix ] + ' ' + value : value)
      }
      if (changeSearch) changeSearch({ item })
    } catch (err) {
      return console.log(err)
    }
  }

  const filteredItem = (value) => {
    try {
      console.log(value)
      if (!filtered) return onChange(value)
      const formattedItem = []
      dropDownList.forEach(filterItem => {
        let copySearch = JSON.parse(JSON.stringify(value))
        let pattern = new RegExp(copySearch.replace(/[{()}]|\s|\+/g, ''), 'gi')
        let formattedFilteredItems = JSON.parse(JSON.stringify(((prefix ? filterItem[ prefix ] + ' ' : '') + filterItem[ itemKey ]).replace(/[{()}]|\s|\+/gi, '')))
        if (formattedFilteredItems.search(pattern) >= 0) {
          formattedItem.push(filterItem)
        }
      })
      console.log(formattedItem)
      setDropDownListFiltered(formattedItem)

      if (onChange) onChange(formattedItem)
      if (changeSearchValue) changeSearchValue(value)
    } catch (err) {
      console.log(err)
    }
  }

  const changeInputHandler = ({ target }) => {
    setSearchValue(target.value)
    // setInputValue(target.value)
    filteredItem(target.value)
  }

  const clearValue = () => {
    setSearchValue('')
    if (changeSearchValue) changeSearchValue('')
    filteredItem('')
  }

  return (
    <div style={ { backgroundColor: showDropDown ? '#fff' : '' } }
         className={ styles.root + (showPlaceholderIcon ? ' ' + styles.rootWithIcon : '') }
         onClick={ fieldClickHandler }>
      { showPlaceholderIcon ?
        <Search className={ styles.searchIcon }/>
        : null }

      { searchValue ?
        <div className={ styles.clear } onClick={ clearValue }>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="24" height="24" rx="12" fill="#333333" fillOpacity="0.1"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M16.3675 7.63176C16.5432 7.80749 16.5432 8.09242 16.3675 8.26815L8.26747 16.3682C8.09173 16.5439 7.80681 16.5439 7.63107 16.3682C7.45533 16.1924 7.45533 15.9075 7.63107 15.7318L15.7311 7.63176C15.9068 7.45602 16.1917 7.45602 16.3675 7.63176Z"
                  fill="#333333"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.63107 7.63176C7.80681 7.45602 8.09173 7.45602 8.26747 7.63176L16.3675 15.7318C16.5432 15.9075 16.5432 16.1924 16.3675 16.3682C16.1917 16.5439 15.9068 16.5439 15.7311 16.3682L7.63107 8.26815C7.45533 8.09242 7.45533 7.80749 7.63107 7.63176Z"
                  fill="#333333"/>
          </svg>
        </div> : null }
      <input className={ inputClassName + ' ' + styles.input } type="text" value={ searchValue }
             ref={ inputRef }
             onChange={ changeInputHandler }
             placeholder={ placeholder }/>
      {/*<InputField value={ searchValue } onChange={ filteredItem } placeholder={ placeholder }/>*/ }
      { showDropDown && !disableDropDown ?
        <Fragment>
          <div className={ styles.dropDown } style={ dropDownSizeStyle[ sizeInput ] }>
            <div className={ styles.dropDownContainer } style={ dropDownSizeStyle.dropDownContainer[ sizeInput ] }>
              { dropDownList.length > 0 && dropDownListFiltered.length > 0 ?
                <div className={ styles.itemContainer }>
                  { dropDownListFiltered.map((item, index) => {
                    return (
                      <div key={ index } className={ styles.item }>
                        { item.isLogo ? <img src={ item.logo } alt={ item[ itemKey ] }/> : null }
                        <span onClick={ () => selectItem(item) }>
                          { prefix ? item[ prefix ] + ' ' : '' }
                          { item[ itemKey ] }
                        </span>
                        { secondTitle ?
                          <span
                            onClick={ () => selectItem(item) }
                            className={ styles.secondTitle }>{ item[ secondTitle ] }
                          </span> : null }
                      </div>
                    )
                  }) }
                </div> :
                <div className={ styles.empty }>{ emptyMessage }</div>
              }
            </div>
          </div>
        </Fragment> : null }
    </div>
  )
}

export default withRouter(SearchInputField)
