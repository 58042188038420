export const CHANGE_WINDOW_SIZE = 'CHANGE_WINDOW_SIZE'
export const CHANGE_LOCATION_ORIGIN = 'CHANGE_LOCATION_ORIGIN'
export const CHANGE_WINDOW_SCROLL = 'CHANGE_WINDOW_SCROLL'
export const SET_USER_AGENT = 'SET_USER_AGENT'

export const changeWindowSize = ({ width, height } = {}) => (dispatch, getState) => {
  dispatch({
    type: CHANGE_WINDOW_SIZE,
    size: {
      windowInnerHeight: height || window.innerHeight,
      windowInnerWidth: width || window.innerWidth
    }
  })
}

export const setUserAgent = agent => (dispatch) => {
  dispatch({ type: SET_USER_AGENT, agent })
}

export const changeWindowScroll = ({ pageYOffset } = {}) => {
  let appNode = document.getElementById('App').scrollTop
  return {
    type: CHANGE_WINDOW_SCROLL,
    scroll: {
      pageYOffset: pageYOffset ? pageYOffset : appNode > 0 ? appNode : window.pageYOffset
    }
  }
}

export const changeLocationOrigin = (hostname) => ({
  type: CHANGE_LOCATION_ORIGIN,
  origin: hostname || window.location.origin
})
