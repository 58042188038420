import { Fragment, memo } from 'react'
import { connect } from 'react-redux'
import styles from './SelectedFilters.module.scss'
import Option from '../ListSearch/Option/Option'
import RemoveIcon from '../../../../assets/icons/remove.svg'
import { searchClubs } from "@/store/clubs/filters/search/actions"
import { changeSelectedSettings } from "@/store/clubs/filters/global/actions"

const SelectedFilters = memo(({ selected, changeSelectedSettings, leftBlock, selectedMethodsTypes }) => {
  const { mapCardIsOpen } = leftBlock

  const { price, geo } = selected
  return (
    <div className={ styles.root + (mapCardIsOpen ? ' ' + styles.mapCardIsOpen : '') }>
      <div className={ styles.container }>
        { Object.keys({ ...selected.subways, ...selected.services, ...selected.options, ...selected.nets }).length > 0 || price.min || geo.lat ?
          <div className={ styles.removeAll + ' body2' }
               onClick={ changeSelectedSettings.bind(null, { type: selectedMethodsTypes.removeAll }) }>
            Очистить все
            <RemoveIcon/>
          </div> : null }
        { [ 'subways', 'services', 'options', 'nets' ].map((key, itemKey) => {
          const currentSelectedValue = selected[ key ]
          return (
            <Fragment key={ key }>
              { Object.keys(currentSelectedValue).map((_key, _index) => {
                const item = currentSelectedValue[ _key ]
                return (
                  <Option key={ key + _key } item={ item } icon={ item.icon }
                          titleKey={ 'title' }
                          setIconColorKey={ 'color_hex' }
                          clickRemove={ value => changeSelectedSettings({ data: { name: key, value } }) }
                          isActive={ true }/>
                )
              }) }
            </Fragment>
          )
        }) }
        { price.min ? <Option item={ { label: 'от ' + price.min + ' - до ' + price.max } }
                              clickRemove={ value => changeSelectedSettings({
                                data: { price: { reset: true } },
                                type: selectedMethodsTypes.price
                              }) }
                              isActive={ true }/> : null }
        { geo.lat ? <Option item={ { label: 'Рядом со мной ' } }
                            clickRemove={ value => changeSelectedSettings({
                              data: { geo: { lat: '', lng: '' } },
                              type: selectedMethodsTypes.geo
                            }) }
                            isActive={ true }/> : null }
        {/*{ search ? <Option item={ { label: 'Поиск - ' + (search.length > 18 ? search.slice(0, 18) + '...' : search) } }*/ }
        {/*                   clickRemove={ value => searchClubs('') }*/ }
        {/*                   isActive={ true }/> : null }*/ }
      </div>
    </div>
  )
})

//refact
const mapStateToProps = state => ({
  selected: state.clubs.filters.global.selected,
  search: state.clubs.filters.search.value,
  leftBlock: state.clubs.leftBlock,
  selectedMethodsTypes: state.clubs.filters.global.selectedMethodsTypes,
})

//refact selected
const mapDispatchToProps = dispatch => ({
  searchClubs: value => dispatch(searchClubs(value)),
  changeSelectedSettings: ({ data, type }) => dispatch(changeSelectedSettings({ data, type }))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFilters)
