import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../../../api"

export const SET_POPULAR_CLUBS_FILTERS = 'SET_POPULAR_CLUBS_FILTERS'

export const getPopularFilters = ({ type }) => async (dispatch, getState) => {
  let res = await fetcher(createParamsApi({
    section: 'club',
    method: 'getPopularFilters',
    apiUrlParams: { type },
    errorMessage: 'getPopularFilters'
  }))
  dispatch({ type: SET_POPULAR_CLUBS_FILTERS, popular: { [ type ]: res[ type ] } })
}

export const getAllPopularFilters = () => async (dispatch, getState) => {
  return await Promise.all([
    getPopularFilters({ type: 'services' })(dispatch, getState),
    getPopularFilters({ type: 'options' })(dispatch, getState),
  ])
}
